.spiceItem{
    display: grid;
    align-items: center;
    grid-template-columns: 60px calc(100% - 260px)   200px;

}

.icon{
    margin-right: 10px;
    cursor: pointer;
    color: white;
    padding: 4px;
    border-radius: 4px;
    margin-left: 10px;
}

h4{
    cursor: pointer;
}

.actions{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    justify-self: end;

}
.image {
    width: 40px;
    height: auto;
    object-fit: contain;
}

.updateStatus{
    cursor: pointer;
}

.updateStatus img{
    width: 30px;
    border: 2px solid var(--blue-ligth-2);
    border-radius: 4px;
    margin-left: 6px;

}

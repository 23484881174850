.foundSpiceContainer{
    display: flex;
    height: 26px;
    align-content: center;
    margin-bottom: 10px;
    cursor: pointer;

}
.foundSpiceContainer p{
    margin: 0;
    align-self: center;
    padding-left: 6px;
}

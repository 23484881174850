:root {
  --bg-color: #222c31;
  --bg-color-light: #111d32;
  --bg-color-secondary: #22323a;
  --blue-ligth: #b0caf9;
  --blue-ligth-2: #b0caf973;
  --blue-dark: #0f61ef;
  --font-blue : #314a77;

}


body{
  background: linear-gradient(var(--bg-color), var(--bg-color-light));
  color: var(--bg-color-light);
  min-height: 100vh;
}



.menuItem{
    display: flex;
    justify-content: center;
    align-items: center;

}
.title{
    font-size: 12px;
}


@media only screen and (min-width: 0px) and (max-width: 500px) {
    .menuItem{
        justify-content: start;
    }
}

.label {
    display: block;
    margin-bottom: 0.15rem;
    font-weight: bold;
    font-size: 0.8rem;
    color: var(--font-blue);
}

.input,
.textarea {
    display: block;
    width: 96%;
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #d9e2f1;
    background-color: #fff;
    color: var(--font-blue);
    font-size: 0.8rem;
}

.loginContainer{
    color: var(--blue-ligth);
    width: 60%;
    margin: 40px auto;
    padding: 1rem;
}
.header{
    color: #84b0fc;
    font-size: 24px;
    font-weight: bold;
}


@media only screen and (min-width: 690px) and (max-width: 900px) {
    .loginContainer{
        width: 80%;
        padding: 0;
    }
}

@media only screen and (min-width: 600px) and (max-width: 689px) {
    .loginContainer{
        width: 90%;
        padding: 0;
    }
}
@media only screen and (min-width: 0px) and (max-width: 599px) {
    .loginContainer{
        width: 95%;
        padding: 0;
    }
}

.badge {
    margin-left: 0.5rem;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    background-color: var(--blue-dark);
    color: #fff;
    font-size: 0.8rem;
    width: 14px;
    height: 14px;
    display: flex;
    justify-content: center;
}

.active{
    background-color: white;
    color: var(--blue-dark);
}

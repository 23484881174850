.spice-statuses {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(auto-fit, 3rem);
    list-style: none;
    margin: 0;
    padding: 0;
}

.li {
    width: 3.2rem;
    height: auto;
    border-radius: 6px;
    border: 2px solid transparent;
    padding: 6px;
}

.image {
    width: 100%;
    height: auto;
    object-fit: contain;

}

.selected {
    border: 3px solid #adbfdf6e;
    background-color: #adbfdf82;
}

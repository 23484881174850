.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 9;
}

.modal {
    top: 10%;
    border-radius: 6px;
    padding: 1.5rem;
    width: 30rem;
    max-width: 90%;
    z-index: 10;
}

.modal h2 {
    margin-top: 0;
    color: var(--font-blue)
}

@media only screen and (min-width: 0px) and (max-width: 550px) {
    .modal {
        max-width: 80%;
    }
}

.rootLayoutContainer{
    color: var(--blue-ligth);
    width: 60%;
    margin: 40px auto;
    padding: 1rem;
}

.rootLayout{
    background-color: var(--bg-color-secondary);
    border-radius: 6px;
    padding: 1rem;
    margin-top: 2rem;
}
.headerContainer{
    display: grid;
    grid-template-columns: calc(100% - 80px) 80px;
    grid-template-rows: 100%;
}

.header{
    color: #84b0fc;
    font-size: 24px;
    font-weight: bold;
}

@media only screen and (min-width: 690px) and (max-width: 900px) {
    .rootLayoutContainer{
        width: 80%;
        padding: 0;
    }
}

@media only screen and (min-width: 600px) and (max-width: 689px) {
    .rootLayoutContainer{
        width: 90%;
        padding: 0;
    }
}
@media only screen and (min-width: 0px) and (max-width: 599px) {
    .rootLayoutContainer{
        width: 95%;
        padding: 0;
    }
}

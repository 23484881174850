.menuContainer{
    display: grid;
    grid-template-columns: calc(100% - 80px) 80px;
    grid-template-rows: 100%;
    grid-auto-flow: row;

}
.buttonContainer{
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.list{
    list-style: none;
    padding: 0;
    display: flex;
    margin-bottom: 1rem;
    gap: 1rem;
}
.active{
    color: white;

}
a>div{
    border-bottom: 2px solid transparent;
    padding-bottom: 4px;
}
a.active>div{
   border-bottom: 2px solid var(--blue-dark);
}
a{
    color: var(--blue-ligth);
    text-decoration: none;

}
.list .active-tab-indicator {
    border: 1.5px solid #0f86ef;
    border-radius: 8px;
}

.icon{
    cursor: pointer;
}

@media only screen and (min-width: 0px) and (max-width: 550px) {
    .menuContainer{
        grid-template-columns: 100%;
        grid-template-rows: 40px auto;
        grid-auto-flow: row
    }
    .menuContainer>div{
        grid-column: 1/2;
        grid-row: 1/2;
    }
    .menuContainer>nav{
        grid-column: 1/2;
        grid-row: 2/3;

    }
    .list{
        margin: 0;
        display: flex;
        flex-wrap: wrap;
    }
    .list>li{
        margin-top: 6px;
    }
    .buttonContainer {
        display: grid;
        justify-self: end;
        grid-template-columns: 40px 40px;
        grid-auto-flow: row;
    }
}

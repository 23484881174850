.actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;
}
.simpleButton{
        cursor: pointer;
        background-color: transparent;
        color: var(--font-blue);
        border: none;
        box-shadow: none;
}
.simpleButton:hover{
    color: var(--blue-dark)
}

.addButton {
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    background-color: #0f61ef;
    color: #fff;
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.addButton:hover{
    background-color: #0f3cef;
}
.cancelButton{
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    background-color: transparent;
    color: #314a77;
    border: none;
    box-shadow: none;
}
.cancelButton:hover{
    background-color: var(--blue-ligth-2);
}
.deleteButton{
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    background-color: var(--bg-color-secondary);
    color: #fff;
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    margin-top: 1rem;
}
.deleteButton:hover{
    background-color: var(--bg-color-light);
}
.outerContainer{
    display: grid;
   grid-template-columns: 100px auto;
    grid-template-rows: 100%;
    grid-auto-flow: row;
}
